import React, { Component } from 'react';
import { CSVReader } from 'react-papaparse';
import {Auth} from '../components/Auth';
import Service from '../components/Service';
import { BrowserRouter as Router, Route, Link, Redirect} from "react-router-dom";

const API = process.env.REACT_APP_API;

function CsvEmpty(props) {
  if (props.books.length === 0) {
    return <p className='hint'>Trenutno nije odabran CSV</p>;
  }
  return null;
}

class ProductsImport extends Component {

    constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.state = {
        books: [],
        updateResult: []    
    }
    this.prepareProducts = this.prepareProducts.bind(this);
    this.createProducts = this.createProducts.bind(this);
    
  }

  handleReadCSV = (data) => {
    console.log(data);
    this.setState({
            books: data.data
          });
    //console.log(data.data[0]);
  }

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  }

  handleImportOffer = () => {
    this.fileInput.current.click();
  }

  relations = [
    {title: "ime"},
    {author: "autor"},
    {translator: "prevod"},
    {number_of_pages: "broj_strana"},
    {isbn: "isbn"},
    {dimension: "format"},
    {weight: "tezina"},
    {binding: "povez"},
    {letter: "pismo"},
    {slug: "slug"},
    {tax: "porez"},
    {price: "cena"},
    {sku: "sku"}
  ];



  createProducts(products){
    fetch(`${API}/products/create`,
      {
        method: 'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: JSON.stringify({
            Auth,
          products: products
        })
       })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          this.setState({
            books: []
          })
        },
        (error) => {
          this.setState({
            // isLoaded: true,
            // error
          });
        }
      )
  }

  checkPrice(){
      let price = 100;
      let r = price - (price * 9.090909 / 100);
      console.log('price', r);
  }

    componentDidMount(){
        this.checkPrice();
    }

  prepareProducts(){

    //return;

    let relations = {
      ime: "title",
      autor: "author",
      prevod: "translator",
      broj_strana: "number_of_pages",
      isbn: "isbn",
      format: "dimension",
      tezina: "weight",
      povez: "binding",
      pismo: "letter",
      slug: "slug",
      porez: "tax",
      cena: "price",
      dobavljac: "supplier",
      opis: "description",
      kategorija: "categories",
      prodavnica: "stores",
      sku: "sku"
    };
    console.log(relations);

    let relationsHeads = {};

    let products = [];
    this.state.books.map(function(item, index){

      if(index === 0){

        // iterate through first row to reference the correct names 
        // and translate names to be db compatibile
        item.map(function(h, i){
          relationsHeads[i] = relations[h];
        });

      }else{

        let product = {};
        item.map(function(h, i){
          //relationsHeads[i] = relations[h];
          product[relationsHeads[i]] = h;
        });
        products.push(product);
      }

    });
    console.log('result');
    console.log(products);

    this.createProducts(products);

  }

    updateProductsPrices=()=>{
        this.setState({updateResult: ['Sinhronizacija...']});
        Service.get('/products/updateErpPrices',{},(res)=>{
            this.setState({updateResult: res.info});
        });
    }

  render() {
    return (
      <div>
          <button onClick={this.updateProductsPrices}>Uskladi cene sa ERP-om</button>
          {this.state.updateResult.map(function(item, index){
            return(
                <p key={index} >{item}</p>
              );
          })}
        <CSVReader
          onFileLoaded={this.handleReadCSV}
          inputRef={this.fileInput}
          style={{display: 'none'}}
          onError={this.handleOnError}
        />
        <h1>Unos Proizvoda:</h1>
        <h2>Unesi pojedinacni proizvod:</h2>
        <Link to="/productAdd"><button >Dodaj novi proizvod</button></Link>
        <hr />
        <h2>Unos iz CSV fajla</h2>
        <p className='hint' >Klikni dugme 'Odaberi CSV' da uneses proizvode iz CSV fajla</p>
        <button onClick={this.handleImportOffer}>Odaberi CSV</button>
        <p className='hint' >Proizvodi će se pojaviti u sekciji 'Knjige'</p>
        <h2>Knjige: </h2>
        <CsvEmpty books={this.state.books} />
        <table>
          <tbody>
          {this.state.books.map(function(item, index){
            console.log(item);
            //let imageUrl = 'images/'+ item[0] + '.jpg';
            return(
                
                  
                      <tr key={index} >
                        {/* <img src={images(item[0])} /> */}
                        {item.map(function(itemIn, i){
                          if(i != 14){
                            return(
                                <td key={i}>{itemIn}</td>
                            );
                          }
                        })}
                      </tr>
              );
          })}
          </tbody>
        </table>
        <button onClick={this.prepareProducts}>Snimi proizvode</button>
      </div>
    );
  }

}

export default ProductsImport;
