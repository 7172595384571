import React, { Component } from 'react';
import Service from '../components/Service';
import Reorder, {
  reorder,
  reorderImmutable,
  reorderFromTo,
  reorderFromToImmutable
} from 'react-reorder';

function images(image){
  return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
}


class ProductLists extends Component {

    constructor(props) {
    super(props);
    this.state = {
        topProducts: [],
        lists: [],
        selectedList: '',
        selectedStoreId: 0,
        listReferences: {},
        currentList: []
    }
    
  }


  componentDidMount(){
      this.updateProducts();
  }

  updateProducts=()=>{
      Service.get('/products/getAllTopLists',{},(res)=>{
          this.setState({topProducts: res.data.products, lists: res.data.listNames},()=>{
              //let p = this
              let vr = {}
                res.data.listNames.map(function(item){
                    vr[item.listName] = item.storeId; 
                });
                console.log('vr', vr);
                this.setState({listReferences: vr})
                if(this.state.selectedList){
                    this.getList();
                }


          })
      })
  }

  getList=()=>{
      console.log(this.state);
      let cl = [];
      let l = this.state.topProducts;
      l.map(function(item){
          if(item.list_name === this.state.selectedList){
              cl.push(item);
          }
      },this);
      this.setState({currentList: cl},()=>{
          //console.log('state',this.state);
      })
  }

  onReorder (event, previousIndex, nextIndex, fromId, toId) {
        this.setState({
            currentList: reorder(this.state.currentList, previousIndex, nextIndex)
        });
    }

  changeList=(event)=>{
      this.setState({selectedList: event.target.value, selectedStoreId: this.state.listReferences[event.target.value]}, ()=>{
          this.getList();
      });
  }


  saveList=()=>{
      console.log(this.state.currentList, this.state.selectedStoreId, this.state.selectedList);
      Service.get('/products/reorderTopList',{
          storeId: this.state.selectedStoreId,
          products: this.state.currentList,
          listName: this.state.selectedList },(res)=>{
            console.log(res);
            this.updateProducts();
        });
  }

  handleAddProductId=(event)=>{
      this.setState({addProductId: event.target.value});
  }

    addProduct=()=>{
        console.log('addProductId', this.state.addProductId);
        if(!this.state.selectedStoreId || !this.state.addProductId || !this.state.selectedList){
            console.log('missing data');
            return;
        }
        Service.get('/products/addToList',{
          storeId: this.state.selectedStoreId,
          productId: this.state.addProductId,
          listName: this.state.selectedList },(res)=>{
            console.log(res);
            this.updateProducts();
        });
    }

    removeProduct=(id)=>{
        console.log('remove product', id);
        // if(!this.state.selectedStoreId || !this.state.addProductId || !this.state.selectedList){
        //     console.log('missing data');
        //     return;
        // }
        Service.get('/products/removeFromList',{
          storeId: this.state.selectedStoreId,
          productId: id,
          listName: this.state.selectedList },(res)=>{
            this.updateProducts();
        });
    }

  render() {
    return (
      <div>
          <h1>Top Liste</h1>
            <select value={this.state.selectedList} onChange={this.changeList}>
                <option> Odaberi Top Listu</option>
            {this.state.lists.map(function(item, index){   
                return (         
                        <option value={item.listName} id={item.storeId} name='lists' >{item.listName} - {item.storeId}</option>
                )
            },this)
          }
            </select>
          <p>Lista: {this.state.selectedList}</p>
          <p>Store ID: {this.state.selectedStoreId}</p>
          <h2>Dodaj Proizvod:</h2>
          ID proizvoda: <input type='text' value={this.state.addProductId} onChange={this.handleAddProductId} />
          <button onClick={this.addProduct} >Dodaj proizvod</button>
          <p className="tooltip" >Premesti proizvode da napravis zeljeni redosled.<br />Na kraju klikni na dugme 'Snimi promene' da sačuvaš promene.</p>
          

          <Reorder className='dragged'
            reorderId="my-list" // Unique ID that is used internally to track this list (required)
            reorderGroup="reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
            //getRef={this.storeRef.bind(this)} // Function that is passed a reference to the root node when mounted (optional)
            component="ul" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
            placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
            draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
            lock="horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
            holdTime={500} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
            touchHoldTime={500} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
            mouseHoldTime={200} // Hold time before dragging begins with mouse (optional), defaults to holdTime
            onReorder={this.onReorder.bind(this)} // Callback when an item is dropped (you will need this to update your state)
            autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
            disabled={false} // Disable reordering (optional), defaults to false
            disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
            placeholder={
                <div className="custom-placeholder" /> // Custom placeholder element (optional), defaults to clone of dragged element
            }
            >
            {
                this.state.currentList.map((item, index) => (
                <li key={item.productId}>
                    <img src={images(item.slug)} />{index+1}. {item.title}<button onClick={()=>{this.removeProduct(item.id)}}>BUTTON</button>
                </li>
                ))//.toArray()
                /*
                Note this example is an ImmutableJS List so we must convert it to an array.
                I've left this up to you to covert to an array, as react-reorder updates a lot,
                and if I called this internally it could get rather slow,
                whereas you have greater control over your component updates.
                */
            }
            </Reorder>

            <button onClick={this.saveList} >Snimi promene</button>
      </div>
    );
  }

}

export default ProductLists;
