import React, { Component } from 'react';
import { BrowserRouter as Router, Route, withRouter, Link, Redirect } from "react-router-dom";
import Service from "../components/Service";
const queryString = require('query-string');
const parsed = queryString.parse(window.location.search);


const API = process.env.REACT_APP_API;

class Krcko extends Component {

    constructor(props) {
    super(props);
    this.state={
      orders:[],
      numberOfOrders: 0,
      pageButtons:[],
      filter: ''

    }
  }

  perPage = 50

  componentDidMount(){
    
    this.setState({filter: parsed.filter},()=>{
        this.updateOrders();
    })
    // console.log('filter',this.state.filter);
    
  }

  createPageButtons(n){
   
    let b = [];

    for (var i=0; i<n ; i++ ){
      b.push({name: i+1});
    }
    this.setState({pageButtons: b});

  }

  componentWillReceiveProps(nextProps){
    this.updateOrders();
  }

  updateOrders=()=>{
    Service.get("/krcko/getSubscribers",{page:this.page(), perPage:this.perPage, filter: this.state.filter },(res)=>{
      this.setState({orders:res.data.subscribers.sort((a, b) => (parseInt(a.id) < parseInt(b.id)) ? 1 : -1), numberOfOrders: 100},()=>{
        this.createPageButtons(this.numberOfPages());
      })
    })

  }
   page(){
     if(this.props.match.params.page){
       return parseInt(this.props.match.params.page);
     }
     return parseInt(1);
   }

   numberOfPages(){
     return Math.ceil(parseInt(this.state.numberOfOrders)/this.perPage);
   }


   pageUp=()=>{
        let cp = this.page();
        cp += 1;
        if(cp > this.numberOfPages()){
            cp = 1;
        }
        window.location.replace("/orders/"+cp+"?filter="+this.state.filter)
    }

    pageDown=()=>{
        let cp = this.page();
        cp -= 1;
        if(cp < 1){
            cp = this.numberOfPages();
        }
        window.location.replace("/orders/"+cp+"?filter="+this.state.filter)
    }

    goToPage=(event)=>{
      console.log(event.target.id);

      window.location.replace("/orders/"+event.target.id+"?filter="+this.state.filter)
    }

    searchByName=()=>{
        //console.log(this.state.filter);
        window.location.replace("/orders?filter="+this.state.filter);
    }
    

    handleFilter=(event)=>{
        this.setState({filter: event.target.value},()=>{
            console.log(this.state.filter)
        });
    }


  render() {
    return (
      <div>
          <h1>Krcko pretplatnici</h1>
          {/* <div class="pagination">
            <button onClick={this.pageDown}> Page down</button> <input value={this.page()} size="4"/> / {this.numberOfPages(this.page())} <button onClick={this.pageUp}>Page up</button>
 

          </div> */}
          {/* <div>Filter: <input type="text" value={this.state.filter} onChange={this.handleFilter} /><button onClick={this.searchByName}>Pretraži</button></div>
          <div>
          {this.state.pageButtons.map(function(item, index){
              return(
                  <button onClick={this.goToPage} id={item.name}  >{item.name}</button>
              )

          },this)
          }


          </div> */}




          <div className="table-orders">
                <div className="orders-row orders-row-head" >
                      <div className="orders-row-id">&nbsp;ID</div>
                      <div className="orders-row-user">Podaci o korisniku</div>
                      <div className="orders-row-total">Tip pretplate</div>
                      <div className="orders-row-modified">Kolicčina</div>
                      <div className="orders-row-button">Proizvodi</div>
                    </div>
          {this.state.orders.map(function(item, index){
              return(
                <div key={index} >
                    {/* <p>{item.id} {item.email} {item.billing_address} {item.created_at} {item.total} {item.modified_at} {item.shop} </p>  */}

                              {/* address: "kucica na kraju Gaja"
city: "Gaj"
created_at: "2019-06-19 20:20:42"
email: "una@google.com"
first_last_name: "una jovana"
id: "1"
phone: "011/123"
status: "1"
store_id: "3"
subscription: "2"
subscription_numbers: "1"
updated_at: "2019-06-19 20:20:42" */}
                    
                    <div className="orders-row" >
                      <div className="orders-row-id">ID:&nbsp; <b>{item.id}</b> <br/> Datum:&nbsp;{item.updated_at}</div>
                      <div className="orders-row-user">Ime:&nbsp;{item.first_last_name} <br/>E-mail:&nbsp; {item.email} <br/> Telefon:&nbsp;{item.phone} <br/>Adresa:&nbsp;{item.address} <br/>Grad:&nbsp;{item.city} </div>
                      <div className="orders-row-total">{item.subscription} </div>
                      <div className="orders-row-modified">{item.subscription_numbers}</div>
                      {/* <div className="orders-row-button"><Link to={"/order/" + item.id}><button>Pogledaj</button></Link></div> */}
                    </div>
                    
                     
                     

                      
              
                    
                    

                    
                </div>
                )
            },this)
          }

          </div>
          
      </div>
    );
  }

}

export default Krcko;
