import {Auth} from '../components/Auth';


// const hostname = window && window.location && window.location.hostname;
var api = 'http://api.pp.cekaonica.com/admin';
var images = 'http://api.pp.cekaonica.com/media/';
var secondaryImages = 'http://api.pp.cekaonica.com/secondary-images/';
if(window.location.origin == 'http://localhost:3000' || window.location.origin == 'http://localhost:3006'){
    api = 'http://api.pp.cekaonica.com/admin';
    images = 'http://api.pp.cekaonica.com/media/';
    secondaryImages = 'http://api.pp.cekaonica.com/secondary-images/';
}
if(window.location.origin== 'http://cms.pp.cekaonica.com'){
    api = 'http://api.pp.cekaonica.com/admin';
    images = 'http://api.pp.cekaonica.com/media/';
    secondaryImages = 'http://api.pp.cekaonica.com/secondary-images/';
}
if(window.location.origin == 'https://cms.publikpraktikum.cekaonica.com'){
    api = 'https://api.publikpraktikum.cekaonica.com/admin';
    images = 'https://api.publikpraktikum.cekaonica.com/media/';
    secondaryImages = 'http://api.publikpraktikum.cekaonica.com/secondary-images/';
}
if(window.location.origin == 'https://cms.publikpraktikum.com'){
    api = 'https://api.publikpraktikum.com/admin';
    images = 'https://api.publikpraktikum.com/media/';
    secondaryImages = 'http://api.publikpraktikum.com/secondary-images/';
}

const Service = {

    //api = 'http://api.pp.cekaonica.com/admin'

    images(image){
        return images + image;
    },

    secondaryImages: secondaryImages,

    get(url, params, cb=null){
        params.Auth = Auth;

        // fetch(`${process.env.REACT_APP_API}${url}`,
        fetch(`${api}${url}`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(result.authenticated != false){
                    if(cb){
                        cb(result);
                    }
                }else{
                    Auth.isAuthenticated = false;
                }
            },
            (error) => {
                console.log('error', error);
            }
        )
    },

    api: api,

    uploadProductImages(formData){
      fetch(`${api}/media/upload`, {
        method: 'post',
        headers: {'content-type': 'multipart/form-data'},
        data: formData,
        body: JSON.stringify({
            Auth,
            images: formData
        })
      })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
        },
        (error) => {
          this.setState({
            // isLoaded: true,
            // error
          });
        }
      )
    },

    uploadNewsImages(formData, cb){
    fetch(`${api}/media/uploadNews`,
      {
        method: 'post',
        //headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: formData
       })
      .then(res => res.json())
      .then(
        (result) => {
          //console.log(result);
            cb(result);
            // this.setState({image: result.data});
            //this.getNews();
          
        },
        (error) => {
          console.log('error mus')
        }
      )
    },

    uploadEmailFile(formData, cb){
    fetch(`${this.api}/users/uploadEmail`,
      {
        method: 'post',
        body: formData
       })
      .then(res => res.json())
      .then(
        (result) => {
          //console.log(result);
            cb(result);
            // this.setState({image: result.data});
            //this.getNews();
          
        },
        (error) => {
          this.setState({
            // isLoaded: true,
            // error
          });
        }
      )
    },

}

export default Service;