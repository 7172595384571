
export const PrintOrders = {

    orders: [],

    clearOrders(){
        this.orders = [];
    },

    addOrder(id){
        if(!this.orders.includes(id)){
            this.orders.push(id);
        }
    },

    removeOrder(id){
        if(this.orders.includes(id)){
            const element = (element) => element == id;
            this.orders.splice(this.orders.findIndex(element), 1);
        }
    },

    isExisting(id){
        return this.orders.includes(id) ? true : false;
    }

}