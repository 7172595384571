import React, { Component } from 'react';
import { BrowserRouter as Router, Route, withRouter, Link, Redirect } from "react-router-dom";
import Service from "../components/Service";
import OrderData from "../components/OrderData";
import ReactToPrint from 'react-to-print';
import { CSVLink, CSVDownload } from "react-csv";


const API = process.env.REACT_APP_API;
function images(image){
    return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
  }
  
function pad(num, size){
    var s = "00000000000000" + num;
    return s.substr(s.length-size);
}

let parsePhone =(n)=>{
  let cleaned = n.replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + ' ' + match[2] + ' ' + match[3]
  }else{
    return cleaned;
  }
  // return null
}

class Order extends Component {

    constructor(props) {
    super(props);
    this.state={
        order: [{id:1}],
        products: [],
        csvData: []
    }
  }
  componentDidMount(){
    this.updateOrder();
    console.log('pad',this.pad(233, 10));
  }

  generateCsvData=()=>{
        let csvData = [
            ["ReferenceID", "SBranchID", "SName", "SAddress", "STownID", "STown", "SCName", "SCPhone", "PuBranchID", "PuName", "PuAddress", "PuTownID", "PuTown", "PuCName", "PuCPhone", "RBranchID", "RName", "RAddress", "RTownID", "RTown", "RCName", "RCPhone", "DlTypeID", "PaymentBy", "PaymentType", "BuyOut", "BuyOutAccount", "BuyOutFor", "Value", "Mass", "ReturnDoc", "Packages", "Note", "Content"]
        ];
        let r = [
            this.state.order.id,
            "UK15494",
            "Publik Praktikum doo",
            "Dobrovoljačka 10/I",
            "11080",
            "Zemun",
            "Kontakt",
            "069/187-24-29",
            "UK15494",
            "Publik Praktikum doo",
            "Dobrovoljačka 10/I",
            "11080",
            "Zemun",
            "Kontakt",
            "069/187-24-29",
            "",
            this.state.order.name,
            this.state.order.billing_address,
            this.state.order.zip_code,
            this.state.order.city,
            this.state.order.name,
            parsePhone(this.state.order.phone),
            2,
            0,
            2,
            (this.state.order.total * 100),
            "160-432229-85",
            0,
            this.getShipmentWeight(),
            0,
            0,
            'TT'+pad(this.state.order.id, 10),
            this.state.order.note,
            "knjige"
        ];
        csvData.push(r);
        this.setState({csvData: csvData},()=>{
            console.log('CSV',this.state.csvData);
        });
        console.log("---------")
        console.log(parsePhone(this.state.order.phone));
        console.log(this.state.order.phone);
  }

  getShipmentWeight=()=>{
      let r = 0;
      this.state.products.map(function(item){
          r += item.productData.weight * item.quantity;
      })
      console.log('weight', r);
        return r;
      
  }

  updateOrder=()=>{
    Service.get("/orders/single", {id: this.props.match.params.id}, (res)=>{
        
      this.setState({order:res.data.order[0], products:res.data.products},()=>{
        this.generateCsvData();
        })
    });

  }

  pad = (num, size) => {
    var s = "00000000000000" + num;
    return s.substr(s.length-size);
}

  render() {
    return (
      <div>
          <h1>Porudzbina</h1>
          <button><CSVLink data={this.state.csvData} separator={"|"} filename={"dExpress-"+this.state.order.id+".csv"}>dExpress CSV</CSVLink></button>
          <button>
            <ReactToPrint
                trigger={() => <a href="#">Štampaj</a>}
                content={() => this.componentRef}
            />
            </button>
            <span ref={el => (this.componentRef = el)}>
                {/* <OrderData order={this.state.order} products={this.state.products} ref={el => (this.componentRef = el)}/> */}
                <OrderData order={this.state.order} products={this.state.products} />
                <OrderData order={this.state.order} products={this.state.products} />
            </span>
          {/* <div className="table-orders">

          <p>id:&nbsp; <b>{this.state.order.id}</b></p>
          <p>Ime:&nbsp;{this.state.order.name}</p>
          <p>Email:&nbsp;{this.state.order.email}</p> 
          <p>Telefon:&nbsp;{this.state.order.phone}</p>
          <p>Cena:&nbsp;{this.state.order.total}</p> 
          <p>Datum:&nbsp;{this.state.order.modified_at}</p>
          

          </div>
          <div className="order-products">
          {this.state.products.map(function(item, index){
              return(
                <div key={index}  >
                     <img src={images(item.productData.slug.toLowerCase())} width="50" />

                    <p>ProductID:&nbsp; <b> {item.product_id}</b>&nbsp; Naslov:&nbsp;<b> {item.productData.title}</b>&nbsp; Sku:&nbsp; <b>{item.productData.sku}</b>&nbsp; Količina:&nbsp; <b> {item.quantity}</b>  </p> 


                    
                    
                   
                    

                    
                </div>
                )
            },this)
          }


          </div> */}
          
      </div>
    );
  }

}

export default Order;
