import React, { Component } from 'react';
import {Auth} from '../components/Auth';
import Service from '../components/Service';

const API = process.env.REACT_APP_API;

class Discounts extends Component {


  deleteAllProducts(i){
      alert('BRISANJE PROIZVODA JE ONEMOGUCENO.');
    // Service.get('/products/clear',{},(res)=>{
    //       alert('PROIZVODI OBRISANI!');
    //   })
    
  }

  check(i){
    //alert('vojno je car: ' + i);
    if (window.confirm("Izbrisi sve proizvode, stvarno???")) { 
      this.deleteAllProducts(i);
    }
  }

  render() {
    return (
      <div>
          <h1>Akcije i popusti</h1>
          {/* <img src={window.location.origin + '/ss.jpg'} /> */}
          <h2>Privremeno dugme za brisanje proizvoda:</h2>
          <button onClick={() => this.check('test')}>IZVBRISI PROIZVODE</button>
      </div>
    );
  }

}

export default Discounts;
