import React, { Component } from 'react';
import { BrowserRouter as Router, Route, withRouter, Link, Redirect } from "react-router-dom";
import Service from "../components/Service";
import {PrintOrders} from "../components/PrintOrders";
import PrintPreview from "../views/PrintPreview";
import ReactPaginate from 'react-paginate';
import { CSVLink, CSVDownload } from "react-csv";

const queryString = require('query-string');
const parsed = queryString.parse(window.location.search);


const API = process.env.REACT_APP_API;

const csvData = [
    ["ReferenceID"|"SBranchID"|"SName"|"SAddress"|"STownID"|"STown"|"SCName"|"SCPhone"|"PuBranchID"|"PuName"|"PuAddress"|"PuTownID"|"PuTown"|"PuCName"|"PuCPhone"|"RBranchID"|"RName"|"RAddress"|"RTownID"|"RTown"|"RCName"|"RCPhone"|"DlTypeID"|"PaymentBy"|"PaymentType"|"BuyOut"|"BuyOutAccount"|"BuyOutFor"|"Value"|"Mass"|"ReturnDoc"|"Packages"|"Note"|"Content"],
//   ["firstname", "lastname", "email"],
//   ["Ahmed", "Tomi", "ah@smthing.co.com"],
//   ["Raed", "Labes", "rl@smthing.co.com"],
//   ["Yezzi", "Min l3b", "ymin@cocococo.com"]
];

class Orders extends Component {

    constructor(props) {
    super(props);
    this.state={
      orders:[],
      numberOfOrders: 0,
      pageButtons:[],
      filter: '',
      page: 1,
      selectedOrders: [],
      allSelected: false,
      csvData: csvData

    }
  }

  perPage = 50;
  currentPage = 1;


  componentDidMount(){

      PrintOrders.addOrder(12);
      console.log('po: ', PrintOrders.orders);
    if(parsed.filter){
        this.setState({filter: parsed.filter},()=>{
            this.updateOrders();
        })
    }else{
        this.updateOrders();
    }
    if(this.props.match.params.page){
            this.currentPage = this.props.match.params.page;
        }else{
            this.currentPage = 1;
        }
    // console.log('filter',this.state.filter);
    
  }

  createPageButtons(n){
   
    let b = [];

    for (var i=0; i<n ; i++ ){
      b.push({name: i+1});
    }
    this.setState({pageButtons: b});

  }

  componentWillReceiveProps(nextProps){
    this.updateOrders();
  }

  updateOrders=()=>{
    Service.get("/orders/get",{page:this.page(), perPage:this.perPage, filter: this.state.filter },(res)=>{
      //let o = this.state.orders;

        res.data.orders.map(function(item){
            item.checked = false;
        })
      
      this.setState({orders:res.data.orders, numberOfOrders: res.data.totalNumberOfOrders},()=>{
        // this.createPageButtons(this.numberOfPages());
        
      })
    })

  }
   page(){
     if(this.props.match.params.page){
       return parseInt(this.props.match.params.page);
     }
     return parseInt(1);
   }

   numberOfPages(){
     return Math.ceil(parseInt(this.state.numberOfOrders)/this.perPage);
   }

   getNumberOfPages=()=>{
       return Math.ceil(parseInt(this.state.numberOfOrders)/this.perPage);
   }


   pageUp=()=>{
        let cp = this.page();
        cp += 1;
        if(cp > this.numberOfPages()){
            cp = 1;
        }
        if(this.state.filter){
            window.location.replace("/orders/"+cp+"?filter="+this.state.filter)
        }else{
            window.location.replace("/orders/"+cp)
        }
    }

    pageDown=()=>{
        let cp = this.page();
        cp -= 1;
        if(cp < 1){
            cp = this.numberOfPages();
        }
        if(this.state.filter){
            window.location.replace("/orders/"+cp+"?filter="+this.state.filter)
        }else{
            window.location.replace("/orders/"+cp)
        }
    }

    goToPage=(event)=>{
      console.log(event.target.id);
        if(this.state.filter){
            window.location.replace("/orders/"+event.target.id+"?filter="+this.state.filter)
        }else{
            window.location.replace("/orders/"+event.target.id)
        }
      
    }

    searchByName=()=>{
        //console.log(this.state.filter);
        window.location.replace("/orders?filter="+this.state.filter);
    }
    

    handleFilter=(event)=>{
        this.setState({filter: event.target.value},()=>{
            console.log(this.state.filter)
        });
    }

    handleFocusKey=(event)=>{
        if (event.key === 'Enter') {
            this.searchByName();
        }
    }

    selectAllOrders = event =>{
        let so = [];
        let o = this.state.orders;
        if(!this.state.allSelected){
            o.map(function(item, index){
                if(item.delivery_status === 0){
                    item.checked = true;
                    if(item.checked){
                        so.push(item.id)
                    }
                }
            })
            this.setState({allSelected: true});
        }else{
            o.map(function(item, index){
            
                item.checked = false;
            })
            this.setState({allSelected: false});
        }

        this.setState({orders: o, selectedOrders: so},()=>{

            console.log(this.state.selectedOrders);
        });
    }

    sendOrdersToCsv=()=>{
        Service.get('/orders/dexpressOrders', {orders: this.state.selectedOrders},(res)=>{
            let c = [];
            c.push(["ReferenceID","SBranchID","SName","SAddress","STownID","STown","SCName","SCPhone","PuBranchID","PuName","PuAddress","PuTownID","PuTown","PuCName","PuCPhone","RBranchID","RName","RAddress","RTownID","RTown","RCName","RCPhone","DlTypeID","PaymentBy","PaymentType","BuyOut","BuyOutAccount","BuyOutFor","Value","Mass","ReturnDoc","Packages","Note","Content"]);
            console.log('data', c);
            //if(res.success){
                res.data.orders.map(function(row){
                    c.push(row);
                })
            //}
            this.setState({csvData: c},()=>{
                console.log('csv data', this.state.csvData);
            });
            console.log(res);
            //this.updateOrders();
        })
    }

    sendCheckedOrders=()=>{
        Service.get('/orders/setStatusPublikDelivered', {orders: this.state.selectedOrders},(res)=>{
            console.log(res);
            this.updateOrders();
        })
    }

    selectOrder = event => {
        let so = [];
        let o = this.state.orders;

        o.map(function(item, index){
            if(item.id === parseInt(event.target.id)){
                console.log('found')
                item.checked = !item.checked;
            }
            if(item.checked){
                so.push(item.id)
            }
        })

        this.setState({orders: o, selectedOrders: so},()=>{

            console.log(this.state.selectedOrders);
        });
        // console.log(event.target.checked, event.target.id);
        // if(event.target.checked){
        //     o.push(id: event.target.id, isChecked:);
        // }else{
        //     o.map((item, index)=>{
        //         if(item == event.target.id){
        //             o.splice(index, 1);
        //         }
        //     })
        // }

        // o.forEach(order => {
        // if (order.id === event.target.id)
        //     order.isChecked = event.target.checked
        // })
        // this.setState({ selectedOrders: o },()=>{
        //     console.log(this.state.selectedOrders);
        // })
    }

    changeOrderDeliveryStatus = (event) => { 
        this.setOrderDeliveryStatus(event.target.id, event.target.value);
    }
    
    setOrderDeliveryStatus = (orderId, deliveryStatus) => {
        Service.get("/orders/orderDeliveryStatus",{
            orderId: orderId,
            deliveryStatus: deliveryStatus
            },(res)=>{
                if(res.success){
                    this.updateOrders();
                }
        });
    }

    changePage = page => {
        let link;
        if(page.selected){
            link = `/orders/${page.selected + 1}`;
           // window.location.href = link;
        }else{
            link = `/orders`;
            
        }
        window.location.href = link;
    }


  render() {
    return (
      <div>
          <h1>Porudzbine</h1>
          {/* <Link to="/print-preview">Štampaj</Link> */}
          
          {/* <PrintPreview orders={this.state.selectedOrders} />
          <button onClick={this.sendOrdersToCsv} >Pošalji Dexpress CSV</button>
          <button onClick={this.sendCheckedOrders} >Obeleži poslato</button>
          <CSVLink data={this.state.csvData} separator={"|"}>Download Dexpress Orders</CSVLink>; */}
          
            <div>
                <div className='search-container'><input placeholder="Pretraga..." type="text" value={this.state.filter} onChange={this.handleFilter} onKeyDown={this.handleFocusKey} /><button onClick={this.searchByName}>Pretraži</button></div>
                <ReactPaginate
                    pageCount={this.getNumberOfPages()}
                    pageRangeDisplayed={4}
                    marginPagesDisplayed={2}
                    previousLabel={'<'}
                    nextLabel={'>'}
                    forcePage={this.currentPage - 1}
                    containerClassName={'pager-container'}
                    activeClassName={'pager-selected'}
                    onPageChange={this.changePage}
                />
                {/* <a href="/print-preview" ><button>Print preview</button></a> */}
                   
            </div>


            <table className='cms-table'>
                <thead>
                    <tr>
                        <th>br.<input type="checkbox"  onClick={this.selectAllOrders} checked={this.state.allSelected} ></input></th>
                        <th>Porudžbina</th>
                        <th>Kupac</th>
                        <th>Adresa Placanja</th>
                        <th>Adresa Slanja</th>
                        <th>Status</th>
                        <th>Način plaćanja</th>
                        <th>Total</th>
                    </tr>
                </thead>
                {this.state.orders.map(function(item, index){
                    return(
                        <tr>
                            {/* <p>{item.id} {item.email} {item.billing_address} {item.created_at} {item.total} {item.modified_at} {item.shop} </p>  */}
                            <td className='table-index'>{index + 1}. 
                                { item.delivery_status === 0 &&
                                    <div className="checkbox-light">
                                        <input type="checkbox" id={item.id}  onClick={this.selectOrder} checked={item.checked} ></input>
                                    </div>
                                }

                                { (item.delivery_status === 1 || item.delivery_status === 2) &&
                                    <input type="checkbox" id={item.id}  onClick={this.selectOrder} checked={item.checked} ></input>
                                }
                            </td>
                            <td>ID: <b><Link to={"/order/" + item.id}>{item.id}</Link></b> <br/>{item.shop}<br/>{item.modified_at}</td>
                            <td><b>{item.name}</b><br/>{item.email}<br/>Tel:{item.phone}&nbsp; </td>
                            <td>{item.billing_address}<br/>{item.zip_code}  {item.city}</td>
                            <td><b>{item.shipping_name}</b><br/>{item.shipping_address} <br/>{item.shipping_zip_code} {item.shipping_city}<br/>Opstina:&nbsp;{item.municipality }  </td>
                            <td>
                                { item.status === 0 &&
                                    <span>
                                    <div className='dot' style={{background: 'orange'}}></div>Započeto
                                    </span>
                                }
                                { item.status === 1 &&
                                    <span>
                                        <div className='dot' style={{background: 'green'}}></div>Plaćeno
                                    </span>
                                }
                            
                                <select id={item.id} value={item.delivery_status} onChange={this.changeOrderDeliveryStatus}>
                                    <option value="0" name={item.id}>Nije poslato</option>
                                    <option value="1" name={item.id}>Poslato Dexpressom</option>
                                    <option value="2" name={item.id}>Poslato pp</option>
                                </select>
                            </td>
                            <td>
                                {/* <br/><br/>Način plaćanja: */}
                                { item.payment_method === "pp" &&
                                    <span>Pouzećem</span>
                                }
                                { item.payment_method === "cc" &&
                                    <span>Kreditna kartica</span>
                                }
                            </td>
                            <td><b>{item.total}</b> RSD</td>
                        </tr>
                        )
                    },this)
                }
            </table>     
      </div>
    );
  }

}

export default Orders;
