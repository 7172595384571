import React, { Component } from 'react';
import { render } from 'react-dom'
 
import EmailEditor from 'react-email-editor'
import { BrowserRouter as Router, Route, withRouter, Link, Redirect } from "react-router-dom";
import Service from "../components/Service";


const API = process.env.REACT_APP_API;

class Customers extends Component {

    constructor(props) {
    super(props);
    this.state={
      customers:[],
      numberOfCustomers: 0,
      pageButtons:[],
      selectedFile: null

    }
  }

  perPage = 50

  componentDidMount(){
    this.updateCustomers();
    
  }

  createPageButtons(n){
   
    let b = [];

    for (var i=0; i<n ; i++ ){
      b.push({name: i+1});
    }
    this.setState({pageButtons: b});

  }

  componentWillReceiveProps(nextProps){
    this.updateCustomers();
  }

  updateCustomers=()=>{
    Service.get("/users/get",{page:this.page(), perPage:this.perPage },(res)=>{
      this.setState({customers:res.data.customers, numberOfCustomers: res.data.totalNumberOfCustomers},()=>{
        this.createPageButtons(this.numberOfPages());
      })
    })

  }
   page(){
     if(this.props.match.params.page){
       return parseInt(this.props.match.params.page);
     }
     return parseInt(1);
   }

   numberOfPages(){
     return Math.ceil(parseInt(this.state.numberOfCustomers)/this.perPage);
   }


   pageUp=()=>{
        let cp = this.page();
        console.log('page', this.page());
        cp += 1;
        if(cp > this.numberOfPages()){
            cp = 1;
        }
        window.location.replace("/customers/"+cp)
    }

    pageDown=()=>{
        let cp = this.page();
        cp -= 1;
        if(cp < 1){
            cp = this.numberOfPages();
        }
        window.location.replace("/customers/"+cp)
    }

    goToPage(event){
      console.log(event.target.id);

      window.location.replace("/customers/"+event.target.id)
    }

    exportSubscribers=(event)=>{
        console.log(event.target.getAttribute('storeid'));
        Service.get("/users/getSubscribers",{storeId: event.target.getAttribute('storeid')},(res)=>{
            //console.log(res);
        })
    }

    sendEmails=(html, storeId)=>{

        let file = new Blob([html], {type: 'text/plain'});
        this.setState({selectedFile: file});

        const formData = new FormData()

        //files.forEach((file, i) => {
            formData.append(0, file);
            formData.append('storeId', storeId);
        //})

        console.log(formData);

        Service.uploadEmailFile(formData,(res)=>{
            console.log(res);
            //this.setState({uploading: false});
        })
        // let html = this.exportHtml();
        // console.log('h', html);
        // Service.get("/users/subscriberEmail",{
        //     "subject":"testSubject",
        //     "body":html,
        //     "altBody":"test vojno email"
        // },(res)=>{
        //     //console.log(res);
        // })
    }

    exportHtml = (event) => {
        console.log(event.target.getAttribute("data-store-id"));
        return;
        this.editor.exportHtml(data => {
            const { design, html } = data
            console.log('exportHtml', html)
            this.sendEmails(html);
            //return html;
        })
    }

    downloadTxtFile = () => {
        const element = document.createElement("a");
        const file = new Blob([document.getElementById('myInput').value], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "myFile.txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }
    

    




  render() {
    return (
      <div>
          <h1>Kupci</h1>
          <div>
              <button data-store-id="3" onClick={this.exportHtml} className="publikpraktikum-button" storeid='3' >Posalji Publikpraktikum newsletter</button>
              <button data-store-id="1" onClick={this.exportHtml} className="harmonija-knjige-button" storeid='1' >Posalji Harmonija newsletter</button>
                <button data-store-id="0" onClick={this.exportHtml}>Test Email</button>
                {/* <button onClick={this.sendEmails} >Posalji mejlove</button> */}
                
            
                <EmailEditor
                    ref={editor => this.editor = editor}
                />
          </div>
          <div class="pagination">
            <button onClick={this.pageDown}>{'<'}</button> <input value={this.page()} size="4"/> / {this.numberOfPages(this.page())} <button onClick={this.pageUp}>{'>'}</button>
 

          </div>
          <div>
          {this.state.pageButtons.map(function(item, index){
              return(
                  <button onClick={this.goToPage} id={item.name}  >{item.name}</button>
              )

          },this)
          }


          </div>


          <div className="table-orders">
                <div className="orders-row orders-row-head" >
                      <div className="orders-row-id">&nbsp;ID</div>
                      <div className="orders-row-user">Podaci o korisniku</div>
                      <div className="orders-row-total">Plaćeno</div>
                      <div className="orders-row-modified">Status</div>
                      <div className="orders-row-button">Proizvodi</div>
                    </div>
          {this.state.customers.map(function(item, index){
              return(
                <div key={index} >
                    {/* <p>{item.id} {item.email} {item.billing_address} {item.created_at} {item.total} {item.modified_at} {item.shop} </p>  */}

                    
                    
                    <div className="orders-row" >
                      <div className="orders-row-id">ID:&nbsp; <b>{item.id}</b> <br/> Radnja:&nbsp;{item.shop}<br/> Datum:&nbsp;{item.modified_at}</div>
                      <div className="orders-row-user">Ime:&nbsp;{item.name} <br/>E-mail:&nbsp; {item.email} <br/> Telefon:&nbsp;{item.phone} <br/>Adresa:&nbsp;{item.shipping_address} <br/>Grad:&nbsp;{item.city}&nbsp; Zip:&nbsp;{item.zip_code}<br/>Opstina:&nbsp;{item.municipality } </div>
                      <div className="orders-row-total">Total: <b>{item.total}</b> RSD   </div>
                      <div className="orders-row-modified">
                        { item.status === 0 &&
                          <button style={{background: 'orange'}}>Započeto</button>
                        }
                         { item.status === 1 &&
                          <button style={{background: 'green'}}>Plaćeno</button>
                        }
                      </div>
                      <div className="orders-row-button"></div>
                    </div>
                    
                     
                     

                      
              
                    
                    

                    
                </div>
                )
            },this)
          }

          </div>
          
      </div>
    );
  }

}

export default Customers;
