import React, { Component } from 'react';
import {Auth} from '../components/Auth';
import Service from '../components/Service';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import renderHTML from 'react-render-html';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function performClick(elemId) {
   var elem = document.getElementById(elemId);
   if(elem && document.createEvent) {
      var evt = document.createEvent("MouseEvents");
      evt.initEvent("click", true, false);
      elem.dispatchEvent(evt);
   }
}


const Info = props => (
  
     <div className="product-info"><span className="product-info-label" >{props.label}: </span> {props.value} </div>
  
);

class Product extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            allCategories: [],
            allStores: [],
            id: 0,
            title: '',
            price: 0,
            sku: '',
            author: '',
            authorLink: '',
            binding: '',
            dimension: '',
            weight: '',
            slug: '',
            video: '',
            supplier: '',
            isbn: '',
            description: '',
            numberOfPages: 0,
            letter: '',
            translator: '',
            categories: [],
            stores: [],
            publishedDate: new Date(),
            brandIndex: 0,
            images: [],
            authors: [],
            newAuthor:'',
            newAuthorLink:'',
        }
        this.editProductValue = this.editProductValue.bind(this);
    }

       handleDateChange = date => {
        this.setState({
          publishedDate: date
        });
  }; 

  updateProducts(){
      console.log('date', new Date())
        Service.get('/products/get',{id: this.props.match.params.id},(result)=>{
            this.setState({
                products: result.data.products,
                id: result.data.products[0].id,
                title: result.data.products[0].title,
                price: result.data.products[0].price,
                sku: result.data.products[0].sku,
                author: result.data.products[0].author,
                authorLink: result.data.products[0].author_link,
                binding: result.data.products[0].binding,
                dimension: result.data.products[0].dimension,
                weight: result.data.products[0].weight,
                slug: result.data.products[0].slug,
                video: result.data.products[0].video,
                supplier: result.data.products[0].supplier,
                isbn: result.data.products[0].isbn,
                description: result.data.products[0].description,
                numberOfPages: result.data.products[0].number_of_pages,
                categories: result.data.products[0].categories,
                stores: result.data.products[0].stores,
                letter: result.data.products[0].letter,
                translator: result.data.products[0].translator,
                publishedDate: new Date(result.data.products[0].published_at),
                brandIndex: result.data.products[0].brand_index
            });
            });
        this.getSecondaryImages();
  }

  getAllCategories = () => {
      Service.get('/categories/all',{id: this.props.match.params.id},(res)=>{
          this.setState({allCategories: res.data});
      });

  }

    getAllStores = () => {
        Service.get('/categories/allStores',{id: this.props.match.params.id},(res)=>{
          this.setState({allStores: res.data});
        });
  }

  componentDidMount() {
    this.getAuthors();
    this.updateProducts();
    this.getAllCategories();
    this.getAllStores();
    console.log('page',this.props.match.params.page);
    console.log('filter',this.props.match.params.filter);
  }



  editProductValue(event){
    this.setState({[event.target.id]: event.target.value});

  }

  checkCategory = (categoryId) => {
      let check = false;
      this.state.categories.map(function(category){
          if(category.category_id == categoryId){
              check = true;
          }
      });

      if(check){
          return true;
      }else{
        return false;
      }
  }

  checkStore = (storeId) => {
      let check = false;
      console.log('store', this.state.stores);
      this.state.stores.map(function(store){
          if(store.store_id == storeId){
              check = true;
          }
      });

      if(check){
          return true;
      }else{
        return false;
      }
  }



  updateProduct = () => {
      Service.get('/products/update',{
            id: this.state.id,
            title: this.state.title,
            price: this.state.price,
            sku: this.state.sku,
            author: this.state.author,
            authorLink: this.state.authorLink,
            binding: this.state.binding,
            dimension: this.state.dimension,
            weight: this.state.weight,
            slug: this.state.slug,
            video: this.state.video,
            supplier: this.state.supplier,
            isbn: this.state.isbn,
            description: this.state.description,
            number_of_pages: this.state.numberOfPages,
            categories: this.state.categories,
            letter: this.state.letter,
            translator: this.state.translator,
            publishedAt: this.state.publishedDate,
            brandIndex: this.state.brandIndex
            
      },(res)=>{
        //   this.setState({allStores: res.data});
        });
  }

  expandTopCategory = (event) => {
      let e = event.currentTarget.parentNode.getElementsByClassName('categories-cont')[0];
        console.log('h', e.style.height);
      if(e.style.height == 'auto' || !e.style.height){
        e.style.height = '0';
        event.target.classList.add('arrow-closed');
        event.target.classList.remove('arrow-open');
      }else{
        e.style.height = 'auto';
        event.target.classList.add('arrow-open');
        event.target.classList.remove('arrow-closed');
      }
      console.log(e.style.height);
  }

  handleCategory = (event) => {
      console.log('et', event.target.value);
      if(this.checkCategory(event.target.value)){
          this.removeCategory(event.target.value);
      }else{
          this.addCategory(event.target.value);
      }
  }

  handleStore = (event) => {
      console.log('et', event.target.value);
      if(this.checkStore(event.target.value)){
          this.removeStore(event.target.value);
      }else{
          this.addStore(event.target.value);
      }
  }

  addCategory = (categoryId) => {
      Service.get('/products/addCategory',{
            productId: this.state.id,
            categoryId: categoryId},()=>{
                this.updateProducts();
                this.getAllCategories();
        });
  }

  addStore = (storeId) => {
      Service.get('/products/addStore',{
            productId: this.state.id,
            storeId: storeId
            },()=>{
                this.updateProducts();
                this.getAllStores();
        });
  }

    removeCategory = (categoryId) => {
        Service.get('/products/removeCategory',{
                productId: this.state.id,
                categoryId: categoryId
                },()=>{
                    this.updateProducts();
                    this.getAllCategories();
            });
    
  }

    getSecondaryImages=()=>{
        Service.get('/products/getImages',{
                    productId: this.props.match.params.id
                },(res)=>{
                    if(res.success){
                        this.setState({images: res.data.images})
                    }
                });
    }

    addSecondaryImage=(event)=>{
        Service.get('/products/addImage',{productId: this.props.match.params.id},()=>{
            this.updateProducts();
        })
    }

    removeStore = (storeId) => {
        Service.get('/products/removeStore',{
                productId: this.state.id,
                storeId: storeId
                },()=>{
                    this.updateProducts();
                    this.getAllStores();
        });
        
    }

    getAuthors=()=>{
    
      console.log('ID',this.props.match.params.id)
    
        Service.get(`/products/getAuthors`,{ productId: this.props.match.params.id }, (res) => {
            if(res.success){
                this.setState({authors: res.authors});
            }
        }); 
    }
    
    removeAuthor=(event)=>{
        Service.get('/products/removeauthor',{
            id: event.target.id

        },(res)=>{
            if(res.success){
                console.log('Autor je uspešno izbrisan');
                this.getAuthors();
            }
        });
    }    

    addAuthor=()=>{
        Service.get('/products/addauthor',{
            name: this.state.newAuthor,
            link: this.state.newAuthorLink,
            productId: this.props.match.params.id

        },(res)=>{
            if(res.success){
                this.getAuthors();
                this.setState({newAuthor:'', newAuthorLink:''})
                
            
                console.log('Uspesno ste dodali autora.');
            }
        });
    }    
  

    upload = (event) => {
        const files = Array.from(event.target.files)
        this.setState({ uploading: true })
    
        const formData = new FormData()
    
        files.forEach((file, i) => {
            formData.append(i, file)
        })

        const updateProgress = (l, t) => {
            this.setState({uploaded: l, max: t})
            if(l >= t){
                request.upload.removeEventListener("progress", _listener);
                setTimeout(()=>{
                    this.updateProducts();
                }, 500);
                
            }
        }

        var _listener;

        formData.append('productId', this.props.match.params.id);
    
        console.log(formData);

        var request = new XMLHttpRequest();
        request.open("POST", Service.api+'/products/uploadImage');

        request.upload.addEventListener("progress", function _listener(evt){
            if (evt.lengthComputable) {
                updateProgress(evt.loaded, evt.total);
                // if(evt.loaded >= evt.total){
                //     // this.updateProducts();
                // }
                // console.log("add upload event-listener" + evt.loaded + "/" + evt.total);
            }
        }, false);

        request.send(formData);
    }

    removeSecondaryImage=(event)=>{
        Service.get('/products/removeImage',{productId: this.props.match.params.id, imageId: event.target.id},()=>{
            this.updateProducts();
        })
    }

  handleDescription = (value) => {
    //console.log(value);
    this.setState({ description: value })
  }

  
    handleAuthor = (event) => {
  
        this.setState({ [event.target.id]: event.target.value });

    };
  
   


  

  modules = {
    toolbar: [
      [{ 'header': [2, 3, false] }],
      ['bold', 'italic', 'underline','strike'],
      ['link', 'image'],
      ['clean']
    ],
  }

  formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'link', 'image'
  ]

  render() {
    return (
      <div>
            <h1>Pregled Proizvoda</h1>
            {this.state.products.map(function(item, index){

              let categories = [];
              let stores = [];
              item.categories.map(function(itm){
                  categories.push(itm.name);
              })

              item.stores.map(function(itm){
                  stores.push(itm.name);
              })

              

                return(
                    <div className="product-edit" key={index} >
                        <h3>{this.state.title}</h3>
                        <div className="product-image">
                            <img src={Service.images(item.slug.toLowerCase() + '.jpg')} />
                        </div>
                        <div className="secondary-images">
                            <h3>Dodatne slike: </h3>
                            {this.state.images.map(function(item, index){
                                return(
                                    <div className='secondary-image'>
                                        <img key={index} src={Service.secondaryImages + item.image_name} />
                                        <button className="remove-secondary-image" id={item.id} onClick={this.removeSecondaryImage}>Ukloni</button>
                                    </div>
                                )
                            }, this)

                            }
                            <button className='add-image-button' onClick={()=>{this.uploadInput.click()}} >
                                <span>Dodaj<br/>sliku<br/>+</span>
                               <progress  value={this.state.uploaded} max={this.state.max}></progress> 
                            </button>
                            <input ref={(ref) => this.uploadInput = ref} style={{display: 'none'}} type='file' id='multi' name="userImage[]" className='add-image-button' onChange={this.upload} multiple data-buttonText={"Upload File"} />
                        </div>
                        <div className="product-edit-inputs">
                      <Info label="id" value={item.id} />
                      Naslov: <input type="text" id="title" value={this.state.title} onChange={this.editProductValue} />
                      SKU: <input type="text" id="sku" value={this.state.sku} onChange={this.editProductValue} />
                      Cena: <input type="text" id="price" value={this.state.price} onChange={this.editProductValue} />
                      Autor: <input type="text" id="author" value={this.state.author} onChange={this.editProductValue} />
                      Autor Link: <input type="text" id="authorLink" value={this.state.authorLink} onChange={this.editProductValue} />
                        <div className="secondary-authors">
                            {this.state.authors.map(function(item, index){
                                // for (let i = 0; i <= index; i++) {
                                    return(
                                        <div className='secondary-author'>
                                                Dodatni utor: <input type="text" className="author" value={item.name}/>
                                                Link: <input type="text" className="authorLink" value={item.link} />
                                                <button id={item.id} onClick={this.removeAuthor}>Ukloni autora</button>
                                        </div>
                                    )
                                // }
                                }, this)
                            }
                        </div>
                        <div className='secondary-author'>
                            <label for="newAuthor ">Dodaj novog Autora</label>
                            <input type="text" value={this.state.newAuthor} id='newAuthor' onChange={this.handleAuthor} />

                            <label for="newAuthorLink">Dodaj novi link</label>
                            <input type="text" value={this.state.newAuthorLink} id='newAuthorLink' onChange={this.handleAuthor} />
                            <button onClick={this.addAuthor}>Dodaj autora</button>  

                        
                        </div>
                        <br />
                      Povez: <input type="text" id="binding" value={this.state.binding} onChange={this.editProductValue} />
                      Dimenzije: <input type="text" id="dimension" value={this.state.dimension} onChange={this.editProductValue} />
                      Tezina: <input type="text" id="weight" value={this.state.weight} onChange={this.editProductValue} />
                      Broj strana: <input type="text" id="numberOfPages" value={this.state.numberOfPages} onChange={this.editProductValue} />
                      Slug: <input type="text" id="slug" value={this.state.slug} onChange={this.editProductValue} />
                      Video: <input type="text" id="video" value={this.state.video} onChange={this.editProductValue} />
                      Dobavljac: <input type="text" id="supplier" value={this.state.supplier} onChange={this.editProductValue} />
                      Pismo: <input type="text" id="letter" value={this.state.letter} onChange={this.editProductValue} />
                      Prevodilac: <input type="text" id="translator" value={this.state.translator} onChange={this.editProductValue} />
                      ISBN: <input type="text" id="isbn" value={this.state.isbn} onChange={this.editProductValue} />
                      Datum Izdavanja:<br/><DatePicker
                        selected={this.state.publishedDate}
                        onChange={this.handleDateChange}
                        dateFormat={'yyyy-MM-dd'}
                        showYearDropdown={true}
                    />
                    <br />
                    Brend Pozicija: <input type="text" id="brandIndex" value={this.state.brandIndex} onChange={this.editProductValue} />
                      {/* Opis: <textarea type="text" id="description" value={this.state.description} onChange={this.editProductValue} /> */}
                      <ReactQuill value={this.state.description}
                        className="text-editor"
                                    modules={this.modules}
                                    formats={this.formats}
                                onChange={this.handleDescription} />
                      
                      </div>
                      <button onClick={this.updateProduct}>Sacuvaj promene</button>
                    </div>
                );
                },this)
            }
            


                    

                <div className="product-edit-categories">

                    <h2>PRODAVNICE:</h2>
                    <p className="tooltip">klikni na checkbox da dodas ili skines proizvod iz prodavnice.</p>
                    <div>
                      {this.state.allStores.map(function(store){
                            return(
                                <p className='product-edit-subcategory'>
                                     <input type="checkbox" name="subcategory" value={store.id} onClick={this.handleStore} checked={this.checkStore(store.id)}></input>
                                     {store.name}
                                </p>
                                
                            );
                        },this)
                        }
                    </div>
                          <h2>KATEGORIJE:</h2>
                          <p className="tooltip">klikni na checkbox da dodas ili skines proizvod iz kategorije.</p>
                    <div>
                      {this.state.allCategories.map(function(item){
                            return(
                                
                                <div className='product-edit-categories-cont' >
                                <h4 className="arrow-open" onClick={this.expandTopCategory} >{item.name}</h4>
                                <div className="categories-cont">
                                {item.subcategories.map(function(subcategory){
                                  return(
                                     <p className='product-edit-subcategory'>
                                     <input type="checkbox" name="subcategory" value={subcategory.id} onClick={this.handleCategory} checked={this.checkCategory(subcategory.id)}></input>
                                     {subcategory.name}
                                     </p>
                                        
                                  );
                                },this)
                                }
                                </div>
                                </div>
                                
                            );
                        },this)
                        }
                        </div>
                </div>

                
      </div>
    );
  }

}

export default Product;