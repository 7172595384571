import React, { Component } from 'react';
import {Auth} from '../components/Auth';
import Service from '../components/Service';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import renderHTML from 'react-render-html';

const API = process.env.REACT_APP_API;


// function images(image){
//   return 'http://api.pp.cekaonica.com/media/news/'+ image;
// }

function HandleImage (props) {
    if(props.images){
        //return (<h1>ima slika</h1>)
    }else{
        return (<h2>nije odabrana slika!</h2>)
    }
}

class News extends Component {

    constructor(props) {
    super(props);
    this.state = {
        news: [],
        title: '',
        text: '',
        storeId: 0,
        image: '',
        addNew: false,
        //editorState: EditorState.createEmpty()
    }

  }

  stores = {
      1: 'harmonija knjige',
      2: 'novi sop',
      3: 'publik praktikum'
  }



  getNews(){
    Service.get('/news/get',{},(res)=>{
        this.setState({
            news: res.data
          })
    });
    // fetch(`${API}/news/get`,
    //   {
    //     method: 'post',
    //     headers: {'Content-Type':'application/x-www-form-urlencoded'},
    //     body: JSON.stringify({
    //       Auth
    //     })
    //    })
    //   .then(res => res.json())
    //   .then(
    //     (result) => {
    //       console.log(result);
    //       this.setState({
    //         news: result.data
    //       })
    //     },
    //     (error) => {
    //       this.setState({
    //         // isLoaded: true,
    //         // error
    //       });
    //     }
    //   )
  }

  deleteNews = (event) => {
    Service.get('/news/delete',{id: event.target.value},()=>{
        this.getNews();
    });
    // fetch(`${API}/news/delete`,
    //   {
    //     method: 'post',
    //     headers: {'Content-Type':'application/x-www-form-urlencoded'},
    //     body: JSON.stringify({
    //         Auth,
    //       id: event.target.value
    //     })
    //    })
    //   .then(res => res.json())
    //   .then(
    //     (result) => {
    //       console.log(result);
          
    //         this.getNews();
          
    //     },
    //     (error) => {
    //       this.setState({
    //         // isLoaded: true,
    //         // error
    //       });
    //     }
    //   )
  }

  componentDidMount(){
      this.getNews();
  }

  updateTitle=(event)=>{
      this.setState({title: event.target.value})
  }
  updateText=(event)=>{
      this.setState({text: event.target.value})
  }
  updateStoreId=(event)=>{
      this.setState({storeId: event.target.value})
  }

  addNews=()=>{

      if(!this.state.storeId){
          alert('Nije odabrana prodavnica!');
          return;
      }

      Service.get('/news/add',{
          title: this.state.title,
          text: this.state.text,
          storeId: this.state.storeId,
          image: this.state.image
        },()=>{
            this.getNews();
            this.toggleAddNew();
        });
    // fetch(`${API}/news/add`,
    //   {
    //     method: 'post',
    //     headers: {'Content-Type':'application/x-www-form-urlencoded'},
    //     body: JSON.stringify({
    //         Auth,
    //       title: this.state.title,
    //       text: this.state.text,
    //       storeId: this.state.storeId,
    //       image: this.state.image
    //     })
    //    })
    //   .then(res => res.json())
    //   .then(
    //     (result) => {
    //       console.log(result);

    //         this.getNews();
    //         this.toggleAddNew();
          
    //     },
    //     (error) => {
    //       this.setState({
    //         // isLoaded: true,
    //         // error
    //       });
    //     }
    //   )
  }

    onChange = (event) => {
        const files = Array.from(event.target.files)
        this.setState({ uploading: true })

        const formData = new FormData()

        files.forEach((file, i) => {
        formData.append(i, file)
        })

        console.log('formData:', formData);

        Service.uploadNewsImages(formData,(res)=>{
            this.setState({image: res.data});
        });

    // fetch(`${API_URL}/image-upload`, {
    //   method: 'POST',
    //   body: formData
    // })
    // .then(res => res.json())
    // .then(images => {
    //   this.setState({ 
    //     uploading: false,
    //     images
    //   })
    // })

    // Service.get('/media/uploadNews',{
    //       title: this.state.title,
    //       text: this.state.text,
    //       storeId: this.state.storeId,
    //       image: this.state.image
    //     },()=>{
    //         this.getNews();
    //         this.toggleAddNew();
    //     });

    

    // fetch(`${API}/media/uploadNews`,
    //   {
    //     method: 'post',
    //     //headers: {'Content-Type':'application/x-www-form-urlencoded'},
    //     //body: JSON.stringify({Auth, formData})
    //     body: formData
    //    })
    //   .then(res => res.json())
    //   .then(
    //     (result) => {
    //       console.log(result);

    //         this.setState({image: result.data});
    //         //this.getNews();
          
    //     },
    //     (error) => {
    //       this.setState({
    //         // isLoaded: true,
    //         // error
    //       });
    //     }
    //   )
  }

  toggleAddNew=()=>{
      let r = this.state.addNew;
      r = !r;
      this.setState({addNew: r});
  }

//   onEditorStateChange = (editorState) => {
//       let s = this.state.editorState.getCurrentContent();
//       console.log('raw', s);
//     this.setState({
//       editorState,
//     });
//   };

handleChange = (value) => {
    console.log(value);
    this.setState({ text: value })
  }

  modules = {
    toolbar: [
      [{ 'header': [2, 3, false] }],
      ['bold', 'italic', 'underline','strike'],
      ['link', 'image'],
      ['clean']
    ],
  }

  formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'link', 'image'
  ]

  render() {

    return (
      <div>
          <h1>Vesti</h1>
          <button onClick={this.toggleAddNew}>Dodaj Novu Vest</button>
          {/* <img src={window.location.origin + '/ss.jpg'} /> */}
          {this.state.addNew && 
          <div>
          <h2>Dodavanje vesti:</h2>
          <input type='text' value={this.state.title} onChange={this.updateTitle} placeholder={'naslov vesti...'} style={{width:'30%'}}/>
          <br />
          Odaberi prodavnicu:
          <select value={this.state.storeId} onChange={this.updateStoreId}>
              <option selected >Odaberi prodavnicu</option>
              <option value={1} >Harmonija</option>
              <option value={2} >Novi sop</option>
              <option value={3} >Publik Praktikum</option>
          </select>
          <br />
          <br />
          

          {/* <textarea value={this.state.text} onChange={this.updateText} style={{width: '50%', height: '300px'}} placeholder={'tekst vesti...'}> </textarea> */}
          <br />
          
          {this.state.image && 
            <img src={Service.images(`/news/${this.state.image}`)} height={200} />
            }

            {!this.state.image && 
                <h4 style={{color: '#cc0000'}}>Nije odabrana slika!</h4>
            }
          <div>
            <label for="multi" class="btn">Odaberi sliku:</label>
            <input type='file' id='multi' name="userImage[]" onChange={this.onChange} multiple data-buttonText={"Your label here."} />
          </div>
          <ReactQuill value={this.state.text}
          className="text-editor"
                    modules={this.modules}
                    formats={this.formats}
                  onChange={this.handleChange} />
          <button onClick={this.addNews}>Dodaj novu vest</button>
            
            </div>
          }
          <hr />
            <h2>Sve vesti</h2>
          {this.state.news.map(function(item, index){
              return(
                <div key={index} className="news-preview">
                    <h2>{item.title}</h2>
                    <img src={Service.images(`news/${item.image}`)} style={{width: '200px'}} />
                    <div> {renderHTML(item.text)} </div>
                    <h4>{this.stores[item.store_id]}</h4>
                    <h5>{item.status}</h5>
                    <button value={item.id} onClick={this.deleteNews}>Izbrisi Vest</button>
                    <Link  to={`/news-edit/${item.id}`} >Izmeni vest</Link>
                    <hr />
                </div>
                )
            },this)
          }

      </div>
    );
  }

}

export default News;
