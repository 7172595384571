import React, { Component } from 'react';
var Barcode = require('react-barcode');

function images(image){
    return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
}

function pad(num, size){
    var s = "00000000000000" + num;
    return s.substr(s.length-size);
}

function getDate () {
    var today = new Date();
    var date = today.getDate()+'.'+(today.getMonth()+1)+'.'+today.getFullYear()+' '+today.getHours() + ":" + today.getMinutes();
    return date;
}


//123456|"UKxxxxx"|"Xshop doo"|"Milutina Milankovića 177"|11070|"Beograd (Novi Beorad)"|"Vaša kontakt osoba"|"065/xxxx-xxx"|"UKyyyyy"|"Xshop doo"|"Pere Velimirovića 12/44a"|11090|"Beograd (Rakovica)"|"Vaša kontakt osoba"|"065/xxxx-xxx"||"Vesna Đorđević"|"Kralja Petra I 29/1"|17530|"Surdulica"||"064/420-9793"|2|0|2|450000|325-9500700034685-27|0|0|300|0|"TT0000000001#TT0000000002#"|"Test 1 pošiljka - tekst

  

class OrderData extends Component {

    constructor (props) {
        super(props);
        this.state = {
            orderDate: ''
        }
    }

getShipmentWeight=()=>{
      let r = 0;
      this.props.products.map(function(item){
          r += item.productData.weight * item.quantity;
      })
      console.log('weight', r);
        return r;
      
  }

  componentDidMount(){
      setTimeout(()=>{
      this.setState({orderDate: this.props.order.created_at.slice(0, 10)});
      },500);
  }

  getOrderDate=()=>{
      let d = this.props.order.created_at;
      console.log('date',d)
      return d;
  }
  render() {
    return (
      <div className="order-data">
          <div className="order-data-inner">
          {/* <CSVLink data={csvData}>Download me</CSVLink>; */}
            <div className="table-orders">
                <div class="company-data">
                    <div className="orders-logo-container">
                        <img src="https://publikpraktikum.rs/logo.png" width="36" />
                    </div>
                    <div className="orders-company">
                        <span style={{fontSize: "1.8em"}}><b>"PUBLIK PRAKTIKUM" DOO</b></span>
                        <br/>Dobrovoljačka 10/1 Zemun<br/>
                        <b>Tel:</b> +381 11 420 25 44, +381 11 420 25 45 <br/>
                        <b> Tekući račun:</b> 330-52000260-44 / <b>Matični broj:</b> 17591967<br/>
                        <b> Šifra delatnosti:</b> 5811 / <b>PIB</b> 103635212 
                        <b> e-mail:</b> office@publikpraktikum.rs 
                    </div>
                </div>
                <div className="orders-left">
                    
                    <div className="orders-customer">
                        <b>Porudžbenica br:&nbsp; {this.props.order.id}</b><br/>
                        Ime: {this.props.order.shipping_name}<br/>
                        Adresa: {this.props.order.shipping_address}<br/>
                        Email: {this.props.order.email}<br/>
                        Telefon: {this.props.order.shipping_phone}<br/>
                        Cena: {this.props.order.total}<br/>
                        Datum: {this.state.orderDate}<br/>
                        <b>Napomena:</b> 
                        <div className='order-note'>
                            {this.props.order.note}
                        </div>
                    </div>
                </div>
                <div className="orders-right">
                    <div className="dexpress">
                        <p>D Express doo, Zage Malivuk 1, Beograd</p>
                        <div className="dexpress-sender"><strong>Pošiljalac: </strong>Publik Praktikum, Dobrovoljačka 10/I, 11080 Zemun</div>
                        <div className="dexpress-package">1/1</div>
                        <Barcode width="2" className="barcode" value={'TT'+pad(this.props.order.id, 10)} />
                        <p className="dexpress-reciever-title">Primalac</p>
                        <p className="dexpress-reciever">
                            {this.props.order.name}<br />
                            {this.props.order.billing_address}<br />
                            {this.props.order.zip_code} {this.props.order.city}<br />
                            {/* {this.props.order.municipality}<br /> */}
                            {this.props.order.phone}<br />
                        </p>
                        <div>
                            <div className="half dexpress-order">
                                Referentni broj: {this.props.order.name}
                                <br />Uslugu plaća: nalogodavac - virman
                                <br />Povratna dokumentacija: -
                                <br />Otkupnina: -
                                <br />Sadržaj: knjige
                                <br />Masa: {this.getShipmentWeight() / 1000} Kg
                            </div>
                            <div className="half">
                                Napomena: <br />
                                {this.props.order.note}
                            </div>
                        </div>
                        <p>Vreme štampe: {getDate()}</p>
                    </div>
                </div>

                </div>

                <div className="order-products">
                    <h3>Sadržaj porudžbenice:</h3>
                    <table>
                        <tr>
                            <td>Br.</td>
                            <td>Naziv</td>
                            {/* <td>SKU</td> */}
                            <td>Količina</td>
                            <td>Cena</td>
                            <td>Iznos</td>
                        </tr>
                    {this.props.products.map(function(item, index){
                        return(
                            // <fragment>
                                <tr>
                                    <td>{index + 1}.</td>
                                    <td>{item.productData.title}</td>
                                    {/* <td>{item.productData.sku}</td> */}
                                    <td>{item.quantity}</td>
                                    <td>{item.price}</td>
                                    <td>{parseFloat(item.price * item.quantity).toFixed(2)}</td>
                                </tr>
                            // </fragment>
                            // <div key={index}  >
                            //      <img src={images(item.productData.slug.toLowerCase())} width="50" />

                            //     <p>ProductID:&nbsp; <b> {item.product_id}</b>&nbsp; Naslov:&nbsp;<b> {item.productData.title}</b>&nbsp; Sku:&nbsp; <b>{item.productData.sku}</b>&nbsp; Količina:&nbsp; <b> {item.quantity}</b>  </p> 


                                
                                
                            
                                

                                
                            // </div>
                            )
                        },this)
                    }

                </table>
                <div className='order-totals'>
                    <p>Ukupno za uplatu <b>{this.props.order.total}</b></p>
                    <p>U cenu je uračunat PDV.</p>
                </div>
                
            </div>
            <div className='order-bottom'>
                    <p>Ovaj dokument je štampan iz računara i punovažan je bez pečata i potpisa</p>
            </div>
        </div>
      </div>
    );
  }

}

export default OrderData;
