import React, { Component } from 'react';
import {Auth} from '../components/Auth';



class Login extends Component {

    constructor(props) {
    super(props);
    this.state = {
        loginName: '',
        loginPassword: ''
    }
  }

  // login
    updateLoginName = (event) => {
        this.setState({loginName: event.target.value});
    }
    updateLoginPassword = (event) => {
        this.setState({loginPassword: event.target.value});
    }

  login = () => {
        Auth.userName = this.state.loginName;
        // Auth.password = this.state.loginPassword;
        Auth.authenticate(this.state.loginPassword, () => {
            // redirect to welcome page home page in this case
            this.props.history.push('/');
        })
    }

    signout = () => {
        Auth.signout(()=>{
            // redirect to welcome page home page in this case
            this.props.history.push('/');
        });
    }

  render() {
    return (
      <div>
          <h1>Publik Praktikum - Login</h1>
          <div>
                <h1>Prijava</h1>
                <label>ime:</label><br />
                <input value={this.state.loginName} onChange={this.updateLoginName} />
                <br />
                <label>lozinka:</label><br />
                <input value={this.state.loginPassword} onChange={this.updateLoginPassword} />
                <button onClick={this.login}>Uloguj se</button>
            </div>
      </div>
    );
  }

}

export default Login;
