import Service from '../components/Service';

export const Auth = {

    isAuthenticated: false,
    userName: false,
    token: false,

    authenticate(password, cb) {
        Service.get('/users/login',{userName: this.userName, password: password},(res)=>{
            if(res.success){

        		this.isAuthenticated = true;
                this.token = res.data.token;
                this.userName = res.data.userName;
                this.ted = res.data.ted;

                localStorage.setItem('token', res.data.token);
                localStorage.setItem('userName', res.data.userName);
                localStorage.setItem('ted', res.data.ted);
                
        		console.log(res);
        		setTimeout(cb, 100);
        		
        	}else{
        		this.isAuthenticated = false;
        	}
        }) 	
    },

    logout(cb) {
        Service.get('/users/logout',{userName: this.userName, token: this.token},()=>{
            this.signout(cb);
            });
    },

  
    signout(cb) {
        this.logout();
        this.isAuthenticated = false
        localStorage.removeItem('token');
        localStorage.removeItem('userName');
        console.log(this);
        setTimeout(cb, 100)
    }
}