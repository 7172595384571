import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect} from "react-router-dom";


import Header from './components/Header';
import ProductsImport from './components/ProductsImport';

import Login from './views/Login';
import Home from './views/Home';
import Categories from './views/Categories';
import Discounts from './views/Discounts';
import Orders from './views/Orders';
import Order from './views/Order';
import Customers from './views/Customers';
import Krcko from './views/Krcko';
import Products from './views/Products';
import Product from './views/Product';
import ProductAdd from './views/ProductAdd';
import Images from './views/Images';
import News from './views/News';
import NewsEdit from './views/NewsEdit';
import ProductLists from './views/ProductLists';
import Comments from './views/Comments';
import Suppliers from './views/Suppliers';
import PrintPreview from './views/PrintPreview';
import Banners from './views/Banners'

import {Auth} from './components/Auth';


const checkLoginStatus = () => {

     if (localStorage.getItem('token')) {
        let ted = localStorage.getItem('ted');
        let now = new Date()  
        let time = Math.round(now.getTime() / 1000)
        console.log('date', time);
        if(time < ted - 3600){
            console.log('tes');
            Auth.isAuthenticated = true;
            Auth.token = localStorage.getItem('token');
            Auth.userName = localStorage.getItem('userName');
        }
    
  }else {
      Auth.isAuthenticated = false;
  }

}

{checkLoginStatus()}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    Auth.isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
  )} />
)




class App extends Component {

    constructor(props) {
    super(props);
        
  }

  onComponentDidMount(){
      //checkLoginStatus();
  }

//   onRouteChanged() {
//     console.log("ROUTE CHANGED");
//   }


  render() {
    return (

      <Router>
        <div>
          <Header />
          <div className="content">
            <Route exact path="/login" component={Login} />
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/csv" component={ProductsImport} />
          <PrivateRoute path="/categories" component={Categories} />
          <PrivateRoute path="/discounts" component={Discounts} />
          <PrivateRoute path="/products/:page?/:filter?" component={Products} />
          <PrivateRoute path="/product/:id?/:slug?" component={Product} />
          <PrivateRoute path="/productAdd" component={ProductAdd} />
          <PrivateRoute path="/media/:status?" component={Images} />
          <PrivateRoute path="/news" component={News} />
          <PrivateRoute path="/news-edit/:id" component={NewsEdit} />
          <PrivateRoute path="/top-liste" component={ProductLists} />
          <PrivateRoute path="/komentari" component={Comments} />
          <PrivateRoute path="/dobavljaci" component={Suppliers} />
          <PrivateRoute path="/orders/:page?" component={Orders} />
          <PrivateRoute path="/order/:id" component={Order} />
          <PrivateRoute path="/customers" component={Customers} />
          <PrivateRoute path="/krcko" component={Krcko} />
          <PrivateRoute path="/print-preview" component={PrintPreview} />
          <PrivateRoute path="/banners" component={Banners} />
          
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
