import React, { Component } from 'react';
import { BrowserRouter as Router, Route, withRouter, Link, Redirect } from "react-router-dom";
//import logo from '../images/logo.jpg';

// function Lhb(props) {
// 	if(props.loggedIn) {
// 		return <Link to="/" onClick={() => Auth.signout()} >Logout</Link>
// 	}else{
// 		return <Link to="/login">Login</Link>
// 	}
// 	//return <h1>Hello, {props.loggedIn}</h1>;
// }



class Header extends Component {

  constructor(props) {
    super(props);
        this.state = {
            visibility: 'none'
        }
  }  

// open close menu
handleMenu = () => {
    if(window.innerWidth <= 600){
        let e = document.getElementById('menu');
        if(e.style.display == 'none' || !e.style.display){
            e.style.display = 'block';
        }else{
            e.style.display = 'none';
        }
        console.log(e);
    }
}

componentDidMount=()=>{
    this.interval = setInterval(() => {this.updateMenuVisibility()}, 2000);
}

componentWillUnmount(){
    clearInterval(this.interval);
}

updateMenuVisibility=()=>{
    if(window.location.pathname === '/login'){
        console.log(1);
        this.setState({visibility: 'none'});
    }else{
        console.log(2);
        this.setState({visibility: 'inline-block'});
    }
}

  render() {
    return (
    	<div id="header">
            <span className="header-title">Publik Praktikum CMS</span>
    		<div id="hamburger" onClick={this.handleMenu}>☰</div>
	      	<ul id='menu' onClick={this.handleMenu} style={{display: this.state.visibility}}>
		  	{/*<img className='logo-image' src={logo} /> */}

			    <li>
			      	<Link to="/">Početna</Link>
			    </li>
				<li>
			      	<Link to="/login">Login</Link>
			    </li>
			    <li>
			      	<Link to="/csv">Unos proizvoda</Link>
			    </li>
			    <li>
			      	<Link to="/categories">Kategorije</Link>
			    </li>
			    <li>
			      	<Link to="/discounts">Akcije i popusti</Link>
			    </li>
					<li>
			      	<Link to="/products">Proizvodi</Link>
			    </li>
					<li>
			      	<Link to="/media">Slike Proizvoda</Link>
			    </li>
                <li>
			      	<Link to="/news">Vesti</Link>
			    </li>
                <li>
			      	<Link to="/top-liste">Top Liste</Link>
			    </li>
                <li>
			      	<Link to="/komentari">Komentari</Link>
			    </li>
                <li>
			      	<Link to="/dobavljaci">Dobavljaci</Link>
			    </li>
                <li>
			      	<Link to="/orders">Porudzbine</Link>
			    </li>
                <li>
			      	<Link to="/customers">Kupci</Link>
			    </li>
                <li>
			      	<Link to="/krcko">Krcko</Link>
			    </li>
				<li>
			      	<Link to="/banners">Baneri</Link>
			    </li>
                
			    {/*<li className="header-float-right">
			      	<Link userId={Auth.userId} to="/projects">Projects</Link>
			    </li>
			    <li className="header-float-right" >
			    	<Lhb loggedIn={Auth.isAuthenticated} />
			    </li>
			    <li className="header-float-right">
			      	<Link to="/my-account">My Account</Link>
			    </li>
			    <li className="header-float-right">
			      	<Link to="/register">Register</Link>
			    </li> */}
		 	</ul>
             
	  	</div>
    )
  }
}

export default Header;