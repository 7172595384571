import React, { Component } from 'react';
import {Auth} from '../components/Auth';
import Service from '../components/Service';


const API = process.env.REACT_APP_API;

class Categories extends Component {

    constructor(props) {
    super(props);
    this.state = {
      categories: [],
      allStores: [],
      newParentId: 1, 
      newName: "",
      newDescription: ""
    };

    this.handleCategoryNameChange = this.handleCategoryNameChange.bind(this);
    this.handleCategoryDescriptionChange = this.handleCategoryDescriptionChange.bind(this);
    this.handleParentChange = this.handleParentChange.bind(this);
    
    this.addCategory = this.addCategory.bind(this);
    this.addInput = this.addInput.bind(this);
    

  }

  updateCategories(){

      Service.get('/categories/all',{},(res)=>{
          this.setState({categories: res.data});
      })
  }

  componentDidMount() {
    this.updateCategories();
    this.getAllStores();
  }

  removeCategory(id, name){
    if (window.confirm("Da li zelite da izbrisete kategoriju " + name + "?")) { 
        Service.get('/categories/remove',{id: id},(res)=>{
          this.updateCategories();
      })
    }
  }

  addInput = () => {
    this.setState((prevState) => ({
      inputs: [...prevState.inputs, {newName: "", newDescription: ""}]
    }));
  }

  // handle new category creation
  handleCategoryNameChange(event){
    this.setState({newName: event.target.value});
  }

  handleCategoryDescriptionChange(event){
    this.setState({newDescription: event.target.value});
  }

  handleParentChange(event){
    this.setState({newParentId: event.target.value});
  }
  
  addCategory(){
      Service.get('/categories/create',{
          parentId: this.state.newParentId,
          name: this.state.newName,
          description: this.state.newDescription
          },()=>{
            this.updateCategories();
      })
  }

  handleStore = (event) => {
      console.log('et',event.target.id, event.target.value);
      if(this.checkStoreSingle(event.target.id, event.target.value)){
          this.removeStore(event.target.id, event.target.value);
      }else{
          this.addStore(event.target.id, event.target.value);
      }
  }

  addStore = (categoryId, storeId) => {
      Service.get('/categories/addStore',{
          categoryId: categoryId,
          storeId: storeId
          },()=>{
          this.updateCategories();
      })
  }

  removeStore = (categoryId, storeId) => {
      Service.get('/categories/removeStore',{categoryId: categoryId, storeId: storeId},()=>{
          this.updateCategories();
          this.getAllStores();
      })
  }

  getAllStores = () => {
      Service.get('/categories/allStores',{id: this.props.match.params.id},(res)=>{
          this.setState({allStores: res.data});
      })
  }

    checkStore = (categoryId, storeId) => {
        let check = false;
        let cats = this.state.categories;
        for(var p in cats){
            let cat = cats[p].subcategories
            for(var r in cat){
                if(cat[r].id === categoryId){
                    let stores = cat[r].stores;
                    for(var s in stores){
                       if(stores[s] == storeId){
                            check = true;
                        }
                    }
                }
            }
        }

        if(check){
            return true;
        }else{
            return false;
        }
  }

      checkStoreSingle = (categoryId, storeId) => {
        var check = false;
        let cats = this.state.categories;
        for(var p in cats){
            let cat = cats[p].subcategories
            for(var r in cat){
                if(cat[r].id == categoryId){
                    let stores = cat[r].stores;
                    for(var s in stores){
                       if(stores[s] == storeId){
                            check = true;
                        }
                    }
                }
            }
        }

      if(check){
          console.log('testS');
        return true;
      }else{
          console.log('testS !');
        return false;
      }
  }


  render() {
    return (
      <div>
          <h1>Kategorije Proizvoda</h1>
          <h3>Ubaci nove kategorije:</h3>
          <label className="label">Odaberi Top Kategoriju:
                  <select value={this.state.newParentId} onChange={this.handleParentChange}>
                  {this.state.categories.map(function(item, index){
                    return(
                        
                        <option value={item.id} key={index}>{item.name}</option>
                        
                    
                    )
                  },this
                  )};
                  </select>
                    <br />ime kategorije: <input type="text" value={this.state.newName} onChange={this.handleCategoryNameChange} />
                    <br />opis: <input type="text" value={this.state.newDescription} onChange={this.handleCategoryDescriptionChange} />
                    <br /><button onClick={() => this.addCategory()}>Dodaj kategoriju</button> 
                  </label>
          <hr />     

          {this.state.categories.map(function(item, index){
          return(
              <div className="categories-container" key={index} >
                <h3>{item.name}</h3>
                <div>
                {item.subcategories.map(function(item, index){
                    return(
                        <div>
                      <div className="category" key={index}>{item.name} <button onClick={() => this.removeCategory(item.id, item.name)}>x</button></div>
                        {this.state.allStores.map(function(store){
                                return(
                                    <p className='product-edit-subcategory'>
                                        <input type="checkbox" name="subcategory" id={item.id} value={store.id} onClick={this.handleStore} checked={this.checkStore(item.id, store.id)}></input>
                                        {store.name}
                                    </p>
                                    
                                );
                            },this)
                            }
                        <hr />
                        </div>
                    )
                  },this
                )}
                </div>

                  


              </div>
            );
        },this)}
      </div>
    );
  }

}

export default Categories;
