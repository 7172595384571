import React, { Component } from 'react';
import Service from '../components/Service';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

class Comments extends Component {

    constructor(props) {
    super(props);
    this.state = {
        comments: []
    }

  }

getComments=()=>{
    Service.get('/products/getNewComments',{},(res)=>{
        this.setState({comments: res.data.comments});
    });
}

deleteComment=(event)=>{
    Service.get('/products/deleteComment',{id: event.target.id},(res)=>{
        this.getComments();
    });
}

approveComment=(event)=>{
    Service.get('/products/approveComment',{id: event.target.id},(res)=>{
        this.getComments();
    });
}

componentDidMount(){
    this.getComments();
}

render() {

    return (
      <div>
          <h1>Komentari</h1>

            <h2>Novi komentari</h2>
          {this.state.comments.map(function(item, index){
              return(
                <div key={index} className="comment">
                    <h6>{item.created_at}</h6>
                    <p>{item.text}</p>
                    <button onClick={this.deleteComment} id={item.id} >🗑</button>
                    <button onClick={this.approveComment} id={item.id} >Odobri komentar</button>
                    <hr />
                </div>
                )
            },this)
          }

      </div>
    );
  }

}

export default Comments;
