import React, { Component } from 'react';
import Service from '../components/Service';

class Suppliers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            suppliers: []
        }

    }

    componentDidMount(){
        this.getSuppliers();
    }

    getSuppliers=()=>{
        Service.get('/products/getSuppliers',{},(res)=>{
            this.setState({suppliers: res.data.suppliers});
        })
    }

    updateSupplier=(id)=>{
        console.log('sid', id);
    }

    render() {
        return (
        <div>
            <h2>Dobavljači</h2>
            {this.state.suppliers.map(function(item, index){
                return (
                    <div key={index} className="supplier">
                        <p>{item.id} {item.name} {item.discount}% <input type="text" id={item.id}/><button onClick={()=>{this.updateSupplier(item.id)}}>Azuriraj</button></p>
                    </div>
                )},this)

            }
        </div>
        )
    }

}

export default Suppliers;