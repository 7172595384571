import React, { Component } from 'react';
import { BrowserRouter as Link} from "react-router-dom";
import {Auth} from '../components/Auth';
import Service from '../components/Service';


const API = process.env.REACT_APP_API;

// function images(image){
//   return 'http://api.pp.cekaonica.com/media/'+ image + '.jpg';
// }

const Info = props => (
  
     <div className="product-info"><span className="product-info-label" >{props.label}: </span> {props.value} </div>
  
);

class Products extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            totalProducts: 0,
            perPage: 50,
            search: '',
            page: 1,
            previousPage: 0,
            nextPage: 0,
            selectedSupplier: '',
            suppliers: []
        }

    }



  updateProducts(){
      Service.get('/products/get',{
            page: this.props.match.params.page,
          perPage: this.state.perPage,
          filter: this.state.search,
          supplier: this.state.selectedSupplier
            },(res)=>{
                this.setState({
                products: res.data.products,
                totalProducts: res.data.total_products
                },()=>{
                    this.handlePages();
                });
        });
    // fetch(`${API}/products/get`,
    //   {
    //     method: 'post',
    //     headers: {'Content-Type':'application/x-www-form-urlencoded'},
    //     body: JSON.stringify({
    //         Auth,
    //       page: this.props.match.params.page,
    //       perPage: this.state.perPage,
    //       filter: this.state.search,
    //       supplier: this.state.selectedSupplier
    //     })
    //    })
    //   .then(res => res.json())
    //   .then(
    //     (result) => {
    //       console.log(result);
    //       this.setState({
    //         products: result.data.products,
    //         totalProducts: result.data.total_products
    //       },()=>{
    //           this.handlePages();
    //       });
          
    //       console.log(this.state);
    //     },
    //     (error) => {
    //       this.setState({
    //         // isLoaded: true,
    //         // error
    //       });
    //     }
    //   )
  }

  changeSearch = (event) => {

      this.setState({search: event.target.value});
      this.updateProducts();
  }

    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            this.setState({search: event.target.value});
            this.updateProducts();
        }
    }

  changeProductStatus = (event) => {
      
      this.setProductStatus(event.target.id, event.target.value);
 
  }

  deleteProduct = (productId) => {
      if (window.confirm("Izbrisi proizvod?")) { 
            this.setProductStatus(productId, 'delete');
        }
  }

  setProductStatus = (productId, action) => {
      Service.get(`/products/${action}`,{
            productId: productId
            },()=>{
                this.updateProducts();
        });
    //   fetch(`${API}/products/${action}`,
    //   {
    //     method: 'post',
    //     headers: {'Content-Type':'application/x-www-form-urlencoded'},
    //     body: JSON.stringify({
    //         Auth,
    //       productId: productId
    //     })
    //    })
    //   .then(res => res.json())
    //   .then(
    //     (result) => {
    //       this.updateProducts();
    //     },
    //     (error) => {
    //       this.setState({
    //         // isLoaded: true,
    //         // error
    //       });
    //     }
    //   )
  }

  handlePages=()=>{
      this.previousPage = parseInt(this.state.page) - 1;
      this.nextPage = parseInt(this.state.page) + 1;

      let totalPages = Math.ceil(this.state.totalProducts / this.state.perPage);

      if(this.nextPage >= totalPages){
          this.nextPage = 1;
      }
      if(this.previousPage <= 0){
          this.previousPage = totalPages;
      }

      this.setState({
          previousPage: this.previousPage,
          nextPage: this.nextPage
      })

      console.log('handlePages', this.previousPage);
  }

  componentDidMount() {
    this.updateProducts();
    this.getSuppliers();
    if(this.props.match.params.page){
        this.setState({page: this.props.match.params.page});
        
    }else{

    }
    console.log('page',this.props.match.params.page);
    console.log('filter',this.props.match.params.filter);
  }

    getSuppliers=()=>{
        Service.get('/products/getSuppliers',{},(res)=>{
            this.setState({suppliers: res.data.suppliers});
        })
    }

    selectSupplier=(event)=>{
        console.log(event.target.id);
        this.setState({selectedSupplier: event.target.id},()=>{
            this.updateProducts();
        });
    }

    setProductDiscount=(event)=>{
        console.log(event.target.id, event.target.value);
        Service.get('/products/setDiscount',{productId: event.target.id, discount: event.target.value},(res)=>{
            if(res.success){
                this.updateProducts();
                this.getSuppliers();
            }else{
                alert('greska! '+res.info);
            }
        })
        
    }

    setSticker=(event)=>{
        console.log(event.target.name, event.target.checked);
        Service.get('/products/setSticker',{productId: event.target.name, sticker: event.target.checked},(res)=>{
            if(res.success){
                this.updateProducts();
                this.getSuppliers();
            }else{
                alert('greska! '+res.info);
            }
        })
    }

    setNoDiscount=(event)=>{
        console.log(event.target.name, event.target.value);
        Service.get('/products/setNoDiscount',{productId: event.target.name},(res)=>{
            if(res.success){
                this.updateProducts();
                this.getSuppliers();
            }else{
                alert('greska! '+res.info);
            }
        })
        
    }



  render() {
    return (
      <div>
            <h1>Pregled Proizvoda</h1>
            <div className="products-navigation">
                <a href={`/products/${this.state.previousPage}`} ><button>-</button></a>
                strana {this.state.page} / {Math.ceil(this.state.totalProducts / this.state.perPage)}, Ukupan broj proizvoda: {this.state.totalProducts}
                <a href={`/products/${this.state.nextPage}`} ><button>+</button></a>
                <br />
            <input type='text' value={this.state.search} onKeyPress={this.handleKeyPress} onChange={this.changeSearch} placeholder="Pretrazi..."/> 🔎
            <div className="suppliers">
                {this.state.suppliers.map(function(item, index){
                    return(

                        <button onClick={this.selectSupplier} id={item.name}>{item.name}</button>

                )},this)}
                <button onClick={this.selectSupplier} id={0}>Bez Dobavljaca</button>
                <br />
                <span>Izabrani dobavljac: {this.state.selectedSupplier}</span>

            </div>
            </div>
            {this.state.products.map(function(item, index){

              let categories = [];
              let stores = [];
              item.categories.map(function(itm){
                  categories.push(itm.name);
              })

              item.stores.map(function(itm){
                  stores.push(itm.name);
              })

              let statuses = {0: 'deactivate', 1: 'activate', 2: 'pending', 3: 'soldOut'}

                return(
                    <div className="product-container" key={index} >
                        <a href={'/product/' + item.id + '/' + item.slug}><img src={Service.images(item.slug.toLowerCase()) + '.jpg'} /></a>
                        <div className="product-container-info">
                            <a href={'/product/' + item.id + '/' + item.slug}><span style={{fontSize: '1.2em', fontWeight: 'bold'}}>{item.title}</span></a>
                            <Info label="id" value={item.id} />
                            <Info label="cena" value={item.price} />
                            <Info label="bez poreza" value={item.priceNoTax} />
                            <Info label="autor" value={item.author} />
                            <Info label="dobavljac" value={item.supplier} />
                            <Info label="slug" value={item.slug} />
                            <Info label="SKU" value={item.sku} />
                            <Info label="dobavljac" value={item.supplier} />
                            <Info label="ISBN" value={item.isbn} />
                            <Info label="datum izdanja" value={item.published_at} />
                            <Info label="bez popusta" value={item.no_discount} />
                            <button onClick={()=>{this.deleteProduct(item.id)}}>🗑</button>

                            <select id={item.id} value={statuses[item.status]} onChange={this.changeProductStatus}>
                                <option value='activate' name={item.id} >aktivan</option>
                                <option value='deactivate' name={item.id}>neaktivan</option>
                                <option value='pending' name={item.id}>u pripremi</option>
                                <option value='soldOut' name={item.id}>rasprodato</option>
                            </select>
                        </div>
                        <div className="product-container-info">
                            BEZ POPUSTA: <input type="checkbox" name={item.id} value={item.no_discount} onClick={this.setNoDiscount} checked={item.no_discount}></input>
                            <br />
                            Popust: <input type="number" id={item.id} onBlur={this.setProductDiscount} placeholder={item.discount} />%
                            <br />
                            Sticker: <input type="checkbox" name={item.id} value={item.sticker} onClick={this.setSticker} checked={item.sticker}></input>
                        </div>
                    </div>
                );
                },this)
            }
      </div>
    );
  }

}

export default Products;